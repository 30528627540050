import {REMOVE_ALL_FILTER, REMOVE_FILTER, SET_FILTER} from '../Actions/index'


const initialState = {
    filterArray:[]
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_FILTER:
        let temp=state.filterArray
        temp.push(action.payload.data)
      return {
        ...state,
        filterArray: temp,
      };

    case REMOVE_FILTER:
      let temp_remove=state.filterArray
      temp_remove.splice(action.payload.data,1)

      return {
        ...state,

      };
    case REMOVE_ALL_FILTER:
      // let temp_remove=state.filterArray
      // temp_remove.splice(action.payload.data,1)

      return {
        ...state,
        filterArray: []

      };

  default:
    return state
  }
}
