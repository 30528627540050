/**
 * @flow
 * @relayHash 93114771f3d1902a5f3cd49c83c0bc64
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LogoutUserMutationVariables = {||};
export type LogoutUserMutationResponse = {|
  +logoutUser: ?{|
    +id: ?string
  |}
|};
export type LogoutUserMutation = {|
  variables: LogoutUserMutationVariables,
  response: LogoutUserMutationResponse,
|};
*/


/*
mutation LogoutUserMutation {
  logoutUser {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "logoutUser",
    "storageKey": null,
    "args": null,
    "concreteType": "LogoutUser",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LogoutUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LogoutUserMutation",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "LogoutUserMutation",
    "id": null,
    "text": "mutation LogoutUserMutation {\n  logoutUser {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '173db0014daf8fc4a151459395d60afa';
module.exports = node;
