import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'remixicon/fonts/remixicon.css'
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {createStore } from 'redux'
import rootReducer from './Reducers'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {PersistGate} from 'redux-persist/integration/react';
import { applyMiddleware } from "redux";

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import 'aos/dist/aos.css';
// import {GA_key} from "./serviceApi";
import RoutesComponent from "./RoutesComponent";
import {BrowserRouter as Router} from "react-router-dom";
import { devToolsEnhancer} from 'redux-devtools-extension';
// import ReactPixel from 'react-facebook-pixel';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-loading-skeleton/dist/skeleton.css'
// import 'react-autocomplete-tag/dist/index.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import "./assets/fonts/Anton-Regular.ttf";
// import "./assets/fonts/Gilroy/Gilroy-SemiBold.ttf";
// import "./assets/fonts/Gilroy/Gilroy-Bold.ttf";
// import "./assets/fonts/Gilroy/Gilroy-Medium.ttf";
// import "./assets/fonts/Gilroy/Gilroy-Black.ttf";
// import "./assets/fonts/ARIAL.TTF";
// import "./assets/fonts/geometric415blackbt/geometric-415-black-bt.ttf"
// import "./assets/fonts/HeyOctober.ttf";
// import "./assets/fonts/Helvetica-Neue-Font/Helvetica Neue Medium Extended/Helvetica Neue Medium Extended.ttf";
// import "./assets/fonts/GeoSlab/GeoSlab703 Md BT Bold.ttf";
// import "./assets/fonts/Avenir/AvenirLTStd-Book.otf";

import LogoutUserMutation from "./mutations/LogoutUserMutation";
import {AddOrderData, AddToCart, customerAttach, UserLoggedout} from "./Actions/non_customiser_action";
import {clearNotebookSpecifications} from "./Actions";


const loggerMiddleware = (store) => (next) => (action) => {
    if(action.type === "LOGGEDOUT_USER"){
        LogoutUserMutation({},function (response) {
            if(response.logoutUser !== null){
                localStorage.clear();
                store.dispatch(UserLoggedout({}));
                store.dispatch(AddToCart({}));
                store.dispatch(AddOrderData({}));
                store.dispatch(clearNotebookSpecifications('clear'));
                store.dispatch(customerAttach(false));
                window.location ='/';
            }
        },function (err) {
            localStorage.clear();
            store.dispatch(UserLoggedout({}));
            store.dispatch(customerAttach(false));
            window.location ='/';
        });
    }
   return  next(action);
    // your code
};
const middleware = applyMiddleware(loggerMiddleware);

const persistConfig = {
    key: 'root',
    blacklist: ['imageReducer'],
    storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer,middleware);

const persistor = persistStore(store);

// ReactGA.initialize(GA_key);
// ReactGA.plugin.require('ec');

// Facebook tracking
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: true, 		// enable logs
};
// ReactPixel.init('612688249165594', options);
//
// ReactPixel.pageView();




// Sentry.init({
//     dsn: 'https://6f376bdfd9d44929880e60c4088bd43b@sentry.io/1783822',
//     integrations: [
//         new Integrations.CaptureConsole({
//             levels: ['error']
//         })
//     ],
//     release: '1.0.0',
//     environment: 'prod',
//     // maxBreadcrumbs: 50,
// });


const routing = (

    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                    <RoutesComponent />
            </Router>
        </PersistGate>
    </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
