import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

const App = lazy(() => import("./App"));
const ProductsPage = lazy(() =>
  import("./components/ProductsPage/ProductsPage")
);
const SingleProductPageIndex = lazy(() =>
  import("./components/SingleProductPage/SingleProductPageIndex")
);
const CustomDesignProductListIndex = lazy(() =>
  import("./components/CustomDesignProducts/CustomDesignProductListIndex")
);
const PaymentPage = lazy(() => import("./components/CheckoutPage/PaymentPage"));
const PaperkraftBrandPageIndex = lazy(() =>
  import("./components/PaperkraftBrandPage/PaperkraftBrandPageIndex")
);
const ClassmateBrandPageIndex = lazy(() =>
  import("./components/ClassmateBrandPage/ClassmateBrandPageIndex")
);
const ContactForm = lazy(() => import("./components/ContactForm/ContactForm"));
const CommonTermsAndCondition = lazy(() =>
  import("./components/TermsAndCondition/CommonTermsAndCondition")
);
const PressAndMedia = lazy(() =>
  import("./components/PressAndMedia/PressAndMedia")
);
// const VendorDashboard = lazy(() => import("./components/VendorDashboard/VendorDashboard"));
const MyAccountPageIndex = lazy(() =>
  import("./components/MyAccount/MyAccountPageIndex")
);
const OrderConfirmation = lazy(() =>
  import("./components/OrderConfirmation/OrderConfirmation")
);
const CheckoutPage = lazy(() =>
  import("./components/CheckoutPage/CheckoutPage")
);
const CustomiseForm = lazy(() =>
  import("./components/CustomizeForm/CustomiseForm")
);
const TrackSigleOrder = lazy(() =>
  import("./components/TrackSigleOrder/TrackSigleOrder")
);
const NotFound = lazy(() => import("./NotFound"));
// const LandingPages = lazy(() => import("./components/LandingPages/LandingPages"));
// const MainPaperkraftLandingPage = lazy(() => import("./components/LandingPages/MainPaperkraftLandingPage"));

const AdminDashboardOrders = lazy(() =>
  import("./components/AdminDashboard/Orders/AdminDashboardOrders")
);
const Configuration = lazy(() =>
  import("./components/AdminDashboard/Configuration/Configuration")
);
const AttributesList = lazy(() =>
  import("./components/AdminDashboard/Attributes/List/AttributesList")
);
const AttributeCreate = lazy(() =>
  import("./components/AdminDashboard/Attributes/Create/AttributeCreate")
);
const AttributeEdit = lazy(() =>
  import("./components/AdminDashboard/Attributes/Edit/AttributeEdit")
);
const ProductTypesList = lazy(() =>
  import("./components/AdminDashboard/ProductTypes/List/ProductTypesList")
);
const ProductTypeCreate = lazy(() =>
  import("./components/AdminDashboard/ProductTypes/Create/ProductTypeCreate")
);
const ProductTypeEdit = lazy(() =>
  import("./components/AdminDashboard/ProductTypes/Edit/ProductTypeEdit")
);
const AdminProductList = lazy(() =>
  import("./components/AdminDashboard/Products/List/AdminProductsList")
);
const ProductsCreate = lazy(() =>
  import("./components/AdminDashboard/Products/Create/ProductsCreate")
);
const ProductsEdit = lazy(() =>
  import("./components/AdminDashboard/Products/Edit/ProductsEdit")
);
const CategoriesList = lazy(() =>
  import("./components/AdminDashboard/Categories/List/CategoriesList")
);
const CategoriesCreate = lazy(() =>
  import("./components/AdminDashboard/Categories/Create/CategoriesCreate")
);
const CategoriesEdit = lazy(() =>
  import("./components/AdminDashboard/Categories/Edit/CategoriesEdit")
);
const ProductVariantCreate = lazy(() =>
  import(
    "./components/AdminDashboard/Products/ProductVariant/ProductVariantCreate"
  )
);
const ProductVariantEdit = lazy(() =>
  import(
    "./components/AdminDashboard/Products/ProductVariant/ProductVariantEdit"
  )
);
const CollectionsList = lazy(() =>
  import("./components/AdminDashboard/Collections/List/CollectionsList")
);

const CollectionsCreate = lazy(() =>
  import("./components/AdminDashboard/Collections/Create/CollectionsCreate")
);
const CollectionsEdit = lazy(() =>
  import("./components/AdminDashboard/Collections/Edit/CollectionEdit")
);
const CustomerList = lazy(() =>
  import("./components/AdminDashboard/Customers/List/CustomerList")
);
const CustomerCreate = lazy(() =>
  import("./components/AdminDashboard/Customers/Create/CustomerCreate")
);
const VoucherList = lazy(() =>
  import("./components/AdminDashboard/Vouchers/List/VoucherList")
);
const VoucherCreate = lazy(() =>
  import("./components/AdminDashboard/Vouchers/Create/VoucherCreate")
);
const VoucherEdit = lazy(() =>
  import("./components/AdminDashboard/Vouchers/Edit/VoucherEdit")
);
const StaffMembersList = lazy(() =>
  import("./components/AdminDashboard/StaffMembers/List/StaffMembersList")
);
const StaffMembersCreate = lazy(() =>
  import("./components/AdminDashboard/StaffMembers/Create/StaffMembersCreate")
);
const StaffsMembersEdit = lazy(() =>
  import("./components/AdminDashboard/StaffMembers/Edit/StaffsMembersEdit")
);
const HomeAdminDashboard = lazy(() =>
  import("./components/AdminDashboard/Home/Home")
);
// const MainPaperkraftPenLandingPage = lazy(() => import("./components/LandingPages/MainPaperkraftPenLandingPage"));
const ShippingZonesList = lazy(() =>
  import("./components/AdminDashboard/ShippingMethod/List/ShippingZonesList")
);
const ShippingMethodCreate = lazy(() =>
  import(
    "./components/AdminDashboard/ShippingMethod/Create/ShippingMethodCreate"
  )
);
const ShippingZonePage = lazy(() =>
  import("./components/AdminDashboard/ShippingMethod/ShippingZonePage")
);
const ShippingMethodEdit = lazy(() =>
  import("./components/AdminDashboard/ShippingMethod/Edit/ShippingMethodEdit")
);
const DesignContestHome = lazy(() =>
  import("./components/DesignContest/DesignContestHome/DesignContestHome")
);
const DesignContestAllEntries = lazy(() =>
  import(
    "./components/DesignContest/DesignContestAllEntries/DesignContestAllEntries"
  )
);
const DesignContestUploadImagePage = lazy(() =>
  import(
    "./components/DesignContest/DesignContestUploadImagePage/DesignContestUploadImagePage"
  )
);
const DesignContestSuccessScreen = lazy(() =>
  import(
    "./components/DesignContest/DesignContestSuccessScreen/DesignContestSuccessScreen"
  )
);
const DesignContestUserDesignList = lazy(() =>
  import(
    "./components/DesignContest/DesignContestUserDesignsLit/DesignContestUserDesignList"
  )
);
const DesignContestTerms = lazy(() =>
  import("./components/DesignContest/DesignContestTerms/DesignContestTerms")
);
// const MusicSeriesPenMainPage = lazy(() => import( './components/LandingPages/MusicSeriesPenLandingPage/MusicSeriesPenMainPage'));
// const ClassmateBrandListIndex = lazy(() => import("./components/BrandPage/Classmate/ClassmateBrandListIndex/ClassmateBrandListIndex"));
// const ClassmateBrandSingleProductIndex = lazy(() => import("./components/BrandPage/Classmate/ClassmateBrandSingleProductIndex"));
// const ClassmateBrandPageHome = lazy(() => import("./components/BrandPage/Classmate/ClassmateBrandPageHome/ClassmateBrandPageHome"));

const SchoolBookListIndex = lazy(() =>
  import("./components/SchoolBookList/SchoolBookListIndex")
);
const ProductPackList = lazy(() =>
  import("./components/SchoolBookList/ProductPackList/ProductPackList")
);

// const OrigamiSeries = lazy(() => import("./components/LandingPages/OrigamiSeries/OrigamiSeries"));

const VendorDashboardIndex = lazy(() =>
  import("./components/VendorDashboard/VendorDashboardIndex")
);

const TestComponent = lazy(() => import("./test"));

// const IndexPaperkraftPenLanding = lazy(() => import("./components/LandingPages/Paperkraft/IndexPaperkraftPenLanding"));
// const IndexPaperkraftLuxuryPenLanding = lazy(() => import("./components/LandingPages/Paperkraft/IndexPaperkraftLuxuryPenLanding"));

// const AnimationIndex = lazy(() => import("./components/LandingPages/Paperkraft/AnimationIndex"));
const ClassmatePortfolioHome = lazy(() =>
  import("./components/Portfolio/Classmate/ClassmatePortfolioHome")
);
const ClassmatePortfolioList = lazy(() =>
  import("./components/Portfolio/Classmate/ClassmatePortfolioList")
);
const ClassmatePortfolioProductDetail = lazy(() =>
  import("./components/Portfolio/Classmate/ClassmatePortfolioProductDetail")
);
const Home = lazy(() => import("./components/Portfolio/Paperkraft/Home"));
// const Notebooks = lazy(() => import("./components/Portfolio/Paperkraft/Notebooks"));
// const Detail = lazy(() => import("./components/Portfolio/Paperkraft/Detail"));
const PaperkraftPortfolioList = lazy(() =>
  import("./components/Portfolio/Paperkraft/PaperkraftPortfolioList")
);
const PaperkraftPortfolioProductDetail = lazy(() =>
  import("./components/Portfolio/Paperkraft/PaperkraftPortfolioProductDetail")
);
const CommonPrivacyPolicy = lazy(() =>
  import("./components/TermsAndCondition/CommonPrivacyPolicy")
);
const CommonReturnPolicy = lazy(() =>
  import("./components/TermsAndCondition/CommonReturnPolicy")
);

const ReUpload = lazy(() => import("./components/ReUpload/ReUpload"));
const LicensedDesignsList = lazy(() =>
  import("./components/AdminDashboard/LicensedDesigns/List/LicensedDesignsList")
);
const LicensedDesignsCreate = lazy(() =>
  import(
    "./components/AdminDashboard/LicensedDesigns/Create/LicensedDesignsCreate"
  )
);
const LicensedDesignsEdit = lazy(() =>
  import("./components/AdminDashboard/LicensedDesigns/Edit/LicensedDesignsEdit")
);
const LicensedDesignCategoriesList = lazy(() =>
  import(
    "./components/AdminDashboard/LicensedDesignCategories/List/LicensedDesignCategoriesList"
  )
);
const LicensedDesignCategoriesCreate = lazy(() =>
  import(
    "./components/AdminDashboard/LicensedDesignCategories/Create/LicensedDesignCategoriesCreate"
  )
);
const LicensedDesignCategoriesEdit = lazy(() =>
  import(
    "./components/AdminDashboard/LicensedDesignCategories/Edit/LicensedDesignCategoriesEdit"
  )
);
// const PaperkraftFathersDay = lazy(() => import("./components/LandingPages/PaperkraftFathersDay/PaperkraftFathersDay"));
// const IndependenceDayIndex = lazy(() => import("./components/LandingPages/IndependenceDay"));
// const IndependenceDay = lazy(() => import("./components/LandingPages/IndependenceDay/IndependenceDay"));
// const ARContent = lazy(() => import("./components/LandingPages/IndependenceDay/ARContent"));
const InvoiceList = lazy(() =>
  import("./components/AdminDashboard/Invoice/List/InvoiceList")
);
// const DiwaliOrigamiHero = lazy(() => import("./components/LandingPages/OrigamiSeries/DiwaliOrigamiHero"));
// const DiwaliOrigami = lazy(() => import("./components/LandingPages/OrigamiSeries/DiwaliOrigami"));
// const SpiderMan = lazy(() => import("./components/LandingPages/SpiderMan/SpiderMan"));
const { BuyJusTnc } = lazy(() =>
  import("./components/TermsAndCondition/BuyJusTnc")
);
const BannersList = lazy(() =>
  import("./components/AdminDashboard/Banners/List/BannersList")
);
const BannerCreate = lazy(() =>
  import("./components/AdminDashboard/Banners/Create/BannerCreate")
);
const BannerEdit = lazy(() =>
  import("./components/AdminDashboard/Banners/Edit/BannerEdit")
);
// const BulkOrder = lazy(() => import("./components/LandingPages/BulkOrder/BulkOrder"));
// const BulkOrderSpec = lazy(() => import("./components/LandingPages/BulkOrder/BulkOrderSpec"));
const { VerifyEmail } = lazy(() =>
  import("./components/SignupForm/VerifyEmail")
);
const { MyClassmateAppTermsOfService } = lazy(() =>
  import("./components/TermsAndCondition/myclassmateapp-terms-of-service")
);
const { MyClassmateAppPrivacyPolicy } = lazy(() =>
  import("./components/TermsAndCondition/myclassmateapp-privacy-policy")
);
// const PulseNotebook = lazy(() => import("./components/LandingPages/PulseNotebook/PulseNotebook"));
// const ScienceNotebooks = lazy(() => import("./components/LandingPages/ScienceNotebooks/ScienceNotebooks"));
// const ScienceNotebooksList = lazy(() => import("./components/LandingPages/ScienceNotebooks/ScienceNotebooksList"));
// const {ScienceNotebookFact} = lazy(() => import("./components/LandingPages/ScienceNotebooks/ScienceNotebookFact"));
const TagList = lazy(() =>
  import("./components/AdminDashboard/Tags/List/TagList")
);
const TagCreate = lazy(() =>
  import("./components/AdminDashboard/Tags/Create/TagCreate")
);
const TagEdit = lazy(() =>
  import("./components/AdminDashboard/Tags/Edit/TagEdit")
);
// const QRContent = lazy(() => import("./components/LandingPages/IndependenceDay/QRContent"));
// const DoctorStrange = lazy(() => import("./components/LandingPages/DoctorStrange/DoctorStrange"));
// const DoctorStrangeNotebooksList = lazy(() => import("./components/LandingPages/DoctorStrange/DoctorStrangeNotebookList"));
// const DoctorStrangeARContent = lazy(() => import("./components/LandingPages/DoctorStrange/DoctorStrangeARContent"));
const { FeedBackForm } = lazy(() =>
  import("./components/FeedBackForm/FeedBackForm")
);
// const ThorHome = lazy(() => import("./components/LandingPages/Thor/ThorHome"));
// const SelectSpecification = lazy(() => import("./components/LandingPages/Thor/SelectSpecification"));
// const NotebookPersonalization = lazy(() => import("./components/LandingPages/Thor/NotebookPersonalization"));
// const ThorLEPNotebookList = lazy(() => import("./components/LandingPages/ThorLEP/ThorLEPNotebookList"));
const DisneyTermsAndCondition = lazy(() =>
  import(
    "./components/TermsAndCondition/DisneyTermsAndCondition/DisneyTermsAndCondition"
  )
);
// const PaperkraftQueryForm = lazy(() => import("./components/LandingPages/PaperkraftQueryForm/PaperkraftQueryForm"));
// const InteraktivAR = lazy(() => import("./components/LandingPages/InteraktivAR/InteraktivAR"));
// const InteraktivARVideo = lazy(() => import("./components/LandingPages/InteraktivAR/InteraktivARVideo"));
// const OrigamiARForm = lazy(() => import("./components/LandingPages/OrigamiARForm/OrigamiARForm"));
const TemplateCategoriesList = lazy(() =>
  import(
    "./components/AdminDashboard/TemplateCategories/List/TemplateCategoriesList"
  )
);
const TemplateCategoriesCreate = lazy(() =>
  import(
    "./components/AdminDashboard/TemplateCategories/Create/TemplateCategoriesCreate"
  )
);
const TemplateCategoriesEdit = lazy(() =>
  import(
    "./components/AdminDashboard/TemplateCategories/Edit/TemplateCategoriesEdit"
  )
);

const TemplateDesignDetails = lazy(() =>
  import("./components/AdminDashboard/TemplateDesigns/TemplateDesignDetails")
);
const TemplateDesignsList = lazy(() =>
  import("./components/AdminDashboard/TemplateDesigns/List/TemplateDesignsList")
);

const AllRounterTnc = lazy(() =>
  import("./components/TermsAndCondition/AllRounterTnc")
);
// const OrigamiArFilter = lazy(() => import("./components/LandingPages/OrigamiArFilter"));
const DashboardV = lazy(() =>
  import("./components/AdminDashboard/DashboardV2")
);
const CreatorDesign = lazy(() =>
  import("./components/AdminDashboard/CreatorCommunity/Designs")
);
const Creators = lazy(() =>
  import("./components/AdminDashboard/CreatorCommunity/Creators")
);

class RoutesComponent extends React.Component {
  render() {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <Switch>
          {/*<Route exact path="/d2c" component={App} />*/}
          {/*<Route  path="/d2c/customize"  render={()=>(<Redirect to="/classmate-customised-notebooks/select-pages"/>)}/>*/}
          <Route exact path="/" component={App} />
          {/*<Route path="/classmate-customised-notebooks/:page" component={CustomiseForm}/>*/}
          {/*<Route path="/classmate-customised-notebooks/" component={CustomiseForm} />*/}
          {/*<Route exact path="/classmate-school-and-office-stationery-products"*/}
          {/*       component={ProductsPage}/>*/}
          {/*<Route*/}
          {/*       path="/classmate-school-and-office-stationery-products/:product_name/:product_id"*/}
          {/*       component={SingleProductPageIndex}/>*/}
          {/*<Route exact path="/classmate-designer-notebooks" component={CustomDesignProductListIndex}/>*/}
          {/*<Route  path="/classmate-designer-notebooks/:category/"*/}
          {/*       component={CustomDesignProductListIndex}/>*/}
          {/*<Route  path="/payment" component={PaymentPage}/>*/}
          {/*<Route  path="/paperkraft-notebook-series" component={PaperkraftBrandPageIndex}/>*/}
          {/*<Route  path="/classmate-office-and-school-stationeries"*/}
          {/*       component={ClassmateBrandPageIndex}/>*/}
          {/*<Route  path="/business-inquiry" component={ContactForm}/>*/}
          {/*<Route exact path="/terms-conditions" component={CommonTermsAndCondition}/>*/}
          {/*<Route exact path="/byju-tnc" component={BuyJusTnc}/>*/}
          {/*<Route exact path="/myclassmateapp-terms-of-service" component={MyClassmateAppTermsOfService}/>*/}
          {/*<Route exact path="/myclassmateapp-privacy-policy" component={MyClassmateAppPrivacyPolicy}/>*/}

          {/*<Route exact path="/privacy-policy" component={CommonPrivacyPolicy}/>*/}
          {/*<Route exact path="/return-policy" component={CommonReturnPolicy}/>*/}
          {/*<Route  path="/press_media" component={PressAndMedia}/>*/}
          {/*<Route exact path="/tracking-orders" component={TrackSigleOrder} />*/}
          {/*<Route  path="/tracking-orders/:order_id" component={TrackSigleOrder} />*/}
          {/*<Route  path="/christmas-test" component={LandingPages} />*/}
          {/*<Route  path="/paperkraft-giftbox" component={MainPaperkraftLandingPage} />*/}
          {/*<Route path="/callista-scepter" component={MainPaperkraftPenLandingPage} />*/}
          {/*<Route path="/music-series-pen" component={MusicSeriesPenMainPage} />*/}
          {/*<Route exact path="/classmate-office-and-school-stationeries/:id" component={ClassmateBrandListIndex} />*/}
          {/*<Route path="/classmate-office-and-school-stationeries/:product_name/:product_id" component={ClassmateBrandSingleProductIndex} />*/}
          {/*<Route exact path="/classmate-office-and-school-stationeries" component={ClassmateBrandPageHome} />*/}
          {/*<Route  path="/design-contest-home" component={DesignContestHome} />*/}
          {/*<Route  path="/design-contest-all-entries" component={DesignContestAllEntries} />*/}
          {/*<Route  path="/design-contest-user-design-list" component={DesignContestUserDesignList} />*/}
          {/*<Route  path="/design-contest-upload" component={DesignContestUploadImagePage} />*/}
          {/*<Route  path="/design-contest-success" component={DesignContestSuccessScreen} />*/}
          {/*<Route  path="/design-contest-terms" component={DesignContestTerms} />*/}

          {/*<Route  path="/school-book-list" component={SchoolBookListIndex} />*/}
          {/*<Route  path="/school-book-product/:city/:school/:standard" component={ProductPackList}/>*/}

          {/*<Route path="/origami-notebooks" component={OrigamiSeries} />*/}
          {/*<Route path="/origami-series" component={DiwaliOrigamiHero} />*/}
          {/*<Route path="/3D-Craft-Diwali-Notebooks" component={DiwaliOrigami} />*/}

          <Route path="/vendor-dashboard" component={VendorDashboardIndex} />

          {/*<Route path="/test" component={TestComponent} />*/}

          {/*<Route path="/paperkraft-pen" component={AnimationIndex} />*/}
          {/*<Route path="/paperkraft-pen-premium" component={IndexPaperkraftPenLanding} />*/}
          {/*<Route path="/paperkraft-pen-luxury" component={IndexPaperkraftLuxuryPenLanding} />*/}
          {/*<Route path="/classmate-portfolio" component={ClassmatePortfolioHome} />*/}
          {/*<Route exact path="/classmate-portfolio-list" component={ClassmatePortfolioList} />*/}
          {/*<Route exact path="/classmate-portfolio-list/:category" component={ClassmatePortfolioList} />*/}
          {/*<Route exact path="/classmate-portfolio-product-detail/:sku" component={ClassmatePortfolioProductDetail} />*/}
          {/*<Route exact path="/paperkraft-portfolio" component={Home} />*/}
          {/*<Route exact path="/paperkraft-portfolio-list" component={PaperkraftPortfolioList} />*/}
          {/*<Route exact path="/paperkraft-portfolio-list/:category" component={PaperkraftPortfolioList} />*/}
          {/*<Route exact path="/paperkraft-portfolio-product-detail/:sku" component={PaperkraftPortfolioProductDetail} />*/}

          {/*<Route exact path="/paperkraft-fathers-day" component={PaperkraftFathersDay} />*/}
          {/*<Route exact path="/paperkraft-fathers-day/:designId" component={PaperkraftFathersDay} />*/}

          {/*<Route exact path="/independence-day" component={IndependenceDayIndex} />*/}
          {/*<Route exact path="/ar/independence-day" component={ARContent} />*/}
          {/*<Route exact path="/mycmapp" component={QRContent} />*/}

          {/*<Route exact path="/spiderman" component={SpiderMan} />*/}
          {/*<Route exact path="/bulk-order" component={BulkOrder} />*/}
          {/*<Route exact path="/bulk-order-spec" component={BulkOrderSpec} />*/}
          {/*<Route exact path="/verify-email/:token1/:token2" component={VerifyEmail} />*/}

          {/*<Route exact path="/pulse-journals" component={PulseNotebook} />*/}

          {/*<Route exact path="/science-notebooks" component={ScienceNotebooks} />*/}
          {/*<Route exact path="/science-notebooks-list" component={ScienceNotebooksList} />*/}

          {/*<Route exact path="/science-trivia" component={ScienceNotebookFact} />*/}

          {/*<Route exact path="/doctor-strange" component={DoctorStrange} />*/}
          {/*<Route exact path="/doctor-strange/select-notebooks" component={DoctorStrangeNotebooksList} />*/}
          {/*<Route exact path="/drstrangeqr" component={DoctorStrangeARContent} />*/}
          {/*<Route exact path="/feedback" component={FeedBackForm} />*/}

          {/*<Route exact path="/thor" component={ThorHome} />*/}
          {/*<Route exact path="/thor/:arId" component={ThorHome} />*/}
          {/*<Route exact path="/thor-select-spec" component={SelectSpecification} />*/}
          {/*<Route exact path="/thor-notebook-personalization" component={NotebookPersonalization} />*/}

          {/*<Route exact path="/thor-lep-list" component={ThorLEPNotebookList} />*/}

          {/*<Route exact path="/cmdit30" component={DisneyTermsAndCondition} />*/}

          {/*<Route exact path="/paperkraft-query-form" component={PaperkraftQueryForm} />*/}
          {/*<Route exact path="/interaktiv" component={InteraktivAR} />*/}
          {/*<Route exact path="/interaktiv-ar-video" component={InteraktivARVideo} />*/}
          {/*<Route exact path="/origami-ar-form" component={OrigamiARForm} />*/}

          {/*<Route  path="/order-view/:shippingId" component={ReUpload} />*/}
          {/*<Route  path="/order/:action/:shippingId" component={ReUpload} />*/}

          {/*<Route exact path="/all-rounder-tnc" component={AllRounterTnc}/>*/}

          {/*<Route exact path="/origami-ar-filter" component={OrigamiArFilter}/>*/}

          <Route
            path="/admin-dashboard"
            render={({ match: { url } }) => (
              <>
                <Route
                  path={`${url}/old-home`}
                  component={HomeAdminDashboard}
                />
                <Route
                  path={`${url}/creator-designs`}
                  component={CreatorDesign}
                />
                <Route path={`${url}/creators`} component={Creators} />
                <Route
                  path={`${url}/orders`}
                  component={AdminDashboardOrders}
                />
                <Route
                  exact
                  path={`${url}/products`}
                  component={AdminProductList}
                />
                <Route
                  path={`${url}/products/add`}
                  component={ProductsCreate}
                />
                <Route
                  exact
                  path={`${url}/products/edit/:id`}
                  component={ProductsEdit}
                />
                <Route
                  exact
                  path={`${url}/products/edit/:id/variant/add`}
                  component={ProductVariantCreate}
                />
                <Route
                  exact
                  path={`${url}/products/edit/:id/variant/edit/:variant_id`}
                  component={ProductVariantEdit}
                />
                <Route
                  path={`${url}/configuration`}
                  component={Configuration}
                />
                <Route
                  exact
                  path={`${url}/attributes`}
                  component={AttributesList}
                />
                <Route
                  exact
                  path={`${url}/attributes/add`}
                  component={AttributeCreate}
                />
                <Route
                  exact
                  path={`${url}/attributes/edit/:id`}
                  component={AttributeEdit}
                />
                <Route
                  exact
                  path={`${url}/product-types`}
                  component={ProductTypesList}
                />
                <Route
                  exact
                  path={`${url}/product-types/add`}
                  component={ProductTypeCreate}
                />
                <Route
                  exact
                  path={`${url}/product-types/edit/:id`}
                  component={ProductTypeEdit}
                />
                <Route
                  exact
                  path={`${url}/categories`}
                  component={CategoriesList}
                />
                <Route
                  exact
                  path={`${url}/licensed-designs`}
                  component={LicensedDesignsList}
                />
                <Route
                  exact
                  path={`${url}/licensed-designs/add`}
                  component={LicensedDesignsCreate}
                />
                <Route
                  exact
                  path={`${url}/licensed-designs/edit/:id`}
                  component={LicensedDesignsEdit}
                />
                <Route
                  exact
                  path={`${url}/licensed-categories`}
                  component={LicensedDesignCategoriesList}
                />
                <Route
                  exact
                  path={`${url}/licensed-categories/add`}
                  component={LicensedDesignCategoriesCreate}
                />
                <Route
                  exact
                  path={`${url}/licensed-categories/edit/:id`}
                  component={LicensedDesignCategoriesEdit}
                />

                <Route
                  exact
                  path={`${url}/template-designs/add`}
                  component={TemplateDesignDetails}
                />
                <Route
                  exact
                  path={`${url}/template-designs`}
                  component={TemplateDesignsList}
                />
                <Route
                  exact
                  path={`${url}/template-designs/edit/:id`}
                  component={TemplateDesignDetails}
                />

                <Route
                  exact
                  path={`${url}/template-categories`}
                  component={TemplateCategoriesList}
                />
                <Route
                  exact
                  path={`${url}/template-categories/add`}
                  component={TemplateCategoriesCreate}
                />
                <Route
                  exact
                  path={`${url}/template-categories/edit/:id`}
                  component={TemplateCategoriesEdit}
                />

                <Route
                  exact
                  path={`${url}/categories/add`}
                  component={CategoriesCreate}
                />
                <Route
                  exact
                  path={`${url}/categories/edit/:id`}
                  component={CategoriesEdit}
                />
                <Route
                  exact
                  path={`${url}/collections`}
                  component={CollectionsList}
                />
                <Route
                  exact
                  path={`${url}/collections/add`}
                  component={CollectionsCreate}
                />
                <Route
                  exact
                  path={`${url}/collections/edit/:id`}
                  component={CollectionsEdit}
                />
                <Route
                  exact
                  path={`${url}/customers`}
                  component={CustomerList}
                />
                <Route
                  exact
                  path={`${url}/customers/add`}
                  component={CustomerCreate}
                />
                <Route exact path={`${url}/vouchers`} component={VoucherList} />
                <Route
                  exact
                  path={`${url}/vouchers/add`}
                  component={VoucherCreate}
                />
                <Route
                  exact
                  path={`${url}/vouchers/edit/:id`}
                  component={VoucherEdit}
                />
                <Route
                  exact
                  path={`${url}/staffMembers`}
                  component={StaffMembersList}
                />
                <Route
                  exact
                  path={`${url}/staffMembers/add`}
                  component={StaffMembersCreate}
                />
                <Route
                  exact
                  path={`${url}/staffMembers/edit/:id`}
                  component={StaffsMembersEdit}
                />
                <Route exact path={`${url}/home`} component={DashboardV} />
                <Route
                  exact
                  path={`${url}/shipping`}
                  component={ShippingZonesList}
                />
                <Route
                  exact
                  path={`${url}/shipping/add`}
                  component={ShippingMethodCreate}
                />
                <Route
                  exact
                  path={`${url}/shipping/zone/:id`}
                  component={ShippingZonePage}
                />
                <Route
                  exact
                  path={`${url}/shipping/edit/:id`}
                  component={ShippingMethodEdit}
                />
                <Route
                  exact
                  path={`${url}/invoice-list`}
                  component={InvoiceList}
                />

                <Route exact path={`${url}/banners`} component={BannersList} />
                <Route
                  exact
                  path={`${url}/banners/add`}
                  component={BannerCreate}
                />
                <Route
                  exact
                  path={`${url}/banners/edit/:id`}
                  component={BannerEdit}
                />

                <Route exact path={`${url}/tags`} component={TagList} />
                <Route exact path={`${url}/tags/add`} component={TagCreate} />
                <Route
                  exact
                  path={`${url}/tags/edit/:id`}
                  component={TagEdit}
                />
              </>
            )}
          />

          {/*{*/}
          {/*    this.props.location.pathname  === '/checkout' && (*/}
          {/*        <>*/}
          {/*            {this.props.cart_data.lines && this.props.cart_data.lines.length > 0 ? (*/}
          {/*                <Route  path="/checkout" component={CheckoutPage} />*/}
          {/*            ) : (*/}
          {/*                <Redirect to="/" />*/}
          {/*            )}*/}
          {/*        </>*/}
          {/*    )*/}
          {/*}*/}

          {/*{*/}
          {/*    this.props.location.pathname  === '/MyAccount' && (*/}
          {/*        <>*/}
          {/*            {this.props.user_details.token ? (*/}
          {/*                <Route  path="/MyAccount" component={MyAccountPageIndex} />*/}
          {/*            ) : (*/}
          {/*                <Redirect to="/" />*/}
          {/*            )}*/}
          {/*        </>*/}
          {/*    )*/}
          {/*}*/}

          {/*{*/}
          {/*    this.props.location.pathname  === '/order-confirmation' && (*/}
          {/*        <>*/}
          {/*            {this.props.order_data.order && this.props.order_data.order.orderId ? (*/}
          {/*                <Route  path="/order-confirmation" component={OrderConfirmation} />*/}
          {/*            ) : (*/}
          {/*                <Redirect to="/" />*/}
          {/*            )}*/}
          {/*        </>*/}
          {/*    )*/}
          {/*}*/}

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  user_details: state.UserReducer.user_details,
  order_data: state.OrderReducer.order_data,
  cart_data: state.CartReducer.cart_data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(RoutesComponent);

const LoadingComponent = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Loading...
    </div>
  );
};
