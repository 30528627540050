import {
    CUSTOMER_ATTACH_TO_CART
} from '../Actions/non_customiser_action';



const initialState = {
    customer_attached:false
}


const CustomerAttachReducer =  (state = initialState,action) => {
    switch (action.type) {

        case CUSTOMER_ATTACH_TO_CART:
            return {
                ...state,
                customer_attached: action.payload.customer_attached
            }

        default:
            return state
    }

}

export default CustomerAttachReducer;
