import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation LogoutUserMutation {
      logoutUser{          
        id
      }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {}

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.logoutUser !== null){
                    // localStorage.setItem('user_mobileno',response.createUser.user.mobileNumber);
                    // localStorage.setItem('user_name',response.createUser.user.firstName);
                    callback(response)
                } else {
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
