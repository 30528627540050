import {Environment, RecordSource, Store,} from 'relay-runtime';
import {api} from './serviceApi';
import {
    authMiddleware,
    RelayNetworkLayer,
    urlMiddleware,
    cacheMiddleware,
    progressMiddleware
} from 'react-relay-network-modern';
import {UserLoggedin, UserLoggedout} from "./Actions/non_customiser_action";
import {store as ReduxStore} from "./index"

const oneMinute = 60 * 1000 * 10;



const network = new RelayNetworkLayer(

    [
        cacheMiddleware({
            size: 1024, // max 100 requests
            ttl: oneMinute, // 15 minutes
            clearOnMutation:true,
            updateTTLOnGet:true
        }),
        urlMiddleware({

            url: (req) => Promise.resolve(api),
            headers: {
                'Accept': 'application/json'
            }
        }),

        authMiddleware({

            token: () => {
                let token = localStorage.getItem('user_token');
                if(token && token !== "") {
                    return token;
                }
            },

            tokenRefreshPromise: (req) => {
                debugger
                let storeState = ReduxStore.getState();
                let user_details = storeState.UserReducer.user_details;
                let refreshToken  = localStorage.getItem('refresh_token');
                if(refreshToken){
                    let requestVariables = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                    let variables = {
                        refreshToken:localStorage.getItem('refresh_token')
                    }
                    let body = JSON.stringify({
                        query: "mutation RefreshTokenMutation($refreshToken:String!) {\n       refreshToken(refreshToken:$refreshToken){\n           token\n           refreshToken\n           payload\n       }\n  }",
                        variables
                    })
                    return fetch(api,{...requestVariables,body})
                        .then((res) => res.json())
                        .then((json) => {
                            if(json.data.refreshToken !== null && json.data.refreshToken !== "Token Expired"){
                                localStorage.setItem('user_token',json.data.refreshToken.token);
                                localStorage.setItem('refresh_token',json.data.refreshToken.refreshToken);

                                let user_data = {...user_details};
                                user_data.token = json.data.refreshToken.token;
                                user_data.refreshToken = json.data.refreshToken.refreshToken;
                                ReduxStore.dispatch(UserLoggedin(user_data));
                                return json.data.refreshToken.token;
                            } else {
                                ReduxStore.dispatch(UserLoggedout({}));
                                return 'Authorization failed';
                            }

                        })
                        .catch((err) => {
                            ReduxStore.dispatch(UserLoggedout({}));
                            return 'Authorization failed';
                        });
                }
            },
            prefix:"JWT ",
            allowEmptyToken:true
        }),
        progressMiddleware({
            onProgress: (current, total) => {
                console.log('Downloaded: ' + current + ' B, total: ' + total + ' B');
            },
        }),
        // example of the custom inline middleware
        (next) => async (req) => {
            return await next(req);
        },
    ],
    {
        noThrow:true
    }
); // as second arg you may pass advanced options for RRNL

const source = new RecordSource();
const store = new Store(source);
const environment = new Environment({ network, store });

export default environment;

